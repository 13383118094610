.products-div {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 1rem;
  margin: 5px;
}

.products-header {
  font-size: 30px;
  font-weight: bold;
  margin: 0 10px;
}

.popup-details {
  background-color: #fff;
  width: 60%;
  height: 60%;
  margin: auto;
}

.popup-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  margin-left: auto;
  margin-right: auto;
}

.search-btn {
  margin-left: 5px;
  height: 30px;
  width: 70px;
}

.search {
  width: 200px;
  height: 30px;
}

.details {
  font-size: 17px;
}

.product {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(255, 176, 0, 0.6);
  padding: 20px;
  font-size: 30px;
  text-align: center;
}

.product:hover {
  border: 2px solid rgba(140, 192, 38, 0.8);
  cursor: pointer;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.img {
  width: 200px;
  height: 200px;
}

.product-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.header-t {
  font-size: 25px;
  font-weight: bold;
  margin: 0 10px;
}

.btn {
  width: 100px;
  height: 30px;
  margin: 5px;
}

.close {
  width: 100px;
  border: none;
  cursor: pointer;
  height: 35px;
  background-color: rgb(255, 176, 0);
}

.close:hover {
  border: 1px solid #4ffa16;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.add-to-cart {
  background-color: rgb(255, 176, 0);
  border-radius: 5px;
  height: 40px;
  border: none !important;
  cursor: pointer;
  margin-bottom: 5px;
  padding: 10px;
}

.actions {
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.pad {
  padding: 15px;
}
