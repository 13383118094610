.signup-container {
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 100vh;
  width: 100%;
  background-color: #f5f5f5;
}

.form-control {
  width: 400px;
  height: 30px;
  margin-bottom: 5px;
}

.form-control-btn {
  width: 150px;
  height: 30px;
  margin-bottom: 5px;
}
