.cart-container {
  width: 90%;
  margin: 0 auto;
  border-bottom: 1px solid rgb(255, 176, 0);
  border-top: 1px solid rgb(255, 176, 0);
}

.cart {
  height: 50px;
  border-bottom: 1px solid rgb(255, 176, 0);
}

.cart:hover {
  border: 2px solid rgb(47, 247, 234);
  cursor: pointer;
  text-align: left;
}

.pad {
  text-align: center;
}
